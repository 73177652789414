<template lang="pug">
  .Drugstore
    card-date(
      :date="drugstoreState"
      :acordionNo="true"
      :adjunto="true"
    ).max-w-sm.mx-auto.list-none
    h5.text-sm.m-0.font-bold.max-w-sm.mx-auto.px-3 ♦ Recuerda agendar tu cita dentro de la fecha de vencimiento de la fórmula
    //- calendar(:onClickEvent="handleClickCalendar" ref="calendar")
    .CalendarContainer.mx-auto.my-5
      vc-calendar(
        is-expanded
        v-model="date"
        :available-dates="availablesDays"
        :disabled-dates="disableDays"
        ref="calendar"
        locale="es"
        @dayclick="handleClickCalendar"
        @update:from-page="sendRange")

    h4.text-sm.m-0.font-bold.max-w-sm.mx-auto.px-3(v-if="label") {{ label }}
    h5.text-sm.m-0.font-bold.max-w-sm.mx-auto.px-3 horarios aun disponibles en la fecha seleccionada
    ul.max-w-sm.mx-auto.px-3(v-if="datesByDay && datesByDay.length > 0")
      li(v-for="(dateByDay, dateByDayIndex) in datesByDay" :key="dateByDayIndex").flex.items-center.my-5
        small.text-info_color.w-16.text-center {{ dateByDay.hora }}
        button(
          @click="() => handleGoToDate(dateByDay)"
        ).shadow-bx-small-sm.specialButton.cursor-pointer.text-primary.h-16.flex.ml-3.pl-0.items-center.p-5.rounded-bd-medium.bg-white.w-full.justify-between
          .flex.items-center
            .h-16.bg-good_color.w-2
            p.ml-3 Tomar Cita
          span.material-icons-outlined chevron_right
    ul.max-w-sm.mx-auto.px-3(style="text-align: center;" v-if="label && (datesByDay && datesByDay.length < 1)") 
      br 
      | No hay citas disponibles
      
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";
var moment = require("moment");
moment.locale("es");
import loadingMessage from "../utils/loadingMessage";

export default {
  name: "Drugstore",
  components: {
    Calendar: () => import("../components/Calendar.vue"),
    CardDate: () => import("../components/CardDate.vue"),
  },
  data() {
    return {
      date: moment(),
      min: null,
      max: null,
      availablesDays: {},
      disableDays: {},
      days: {
        LUN: "Lunes",
        MAR: "Martes",
        MIE: "Miércoles",
        JUE: "Jueves",
        VIE: "Viernes",
        SAB: "Sábado",
        DOM: "Domingo",
      },
      label: "",
      datesByDay: [],
      monthNames: [
        "Enero",
        "Febrero",
        "Marzo",
        "Abril",
        "Mayo",
        "Junio",
        "Julio",
        "Agosto",
        "Septiembre",
        "Octubre",
        "Noviembre",
        "Diciembre",
      ],
    };
  },
  created(){
    this.fetchDaysFromDate(moment().startOf('month'));
  },
  computed: {
    ...mapState({
      drugstoreState: (state) => state.drugstore.drugstoreState,
      env: (state) => state.environment.environment.env,
      defaultSheet: (state) => state.environment.environment.defaultSheet,
      availables: (state) => state.appointments.availables,
      availablesDaysMonth: (state) => state.appointments.availablesDaysMonth,
      closeModal: (state) => state.root.closeModal,
      schedule: (state) => state.appointments.schedule,
    }),
  },
  methods: {
    ...mapMutations("drugstore", {
      setStateDrugstore: "setState",
    }),
    ...mapActions("root", [
      "changeTrip",
      "handleShowModal"
    ]),
    ...mapActions(["fetchAvailableMonth", "fetchAvailableDays", "fetchAvailablebyMonths"]),
    handleClickCalendar(day) {
      // const dayText = this.days[day.dayWeekTEXT];
      const dayText = moment(day.date).format('dddd');
      this.label = `${dayText} ${moment(day.date).format('DD')} de ${moment(day.date).format('MMMM')}`;
      this.getDatesByDay(day);
    },
    handleGoToDate(dateByDay) {
      
      this.setStateDrugstore({ key: "drugstoreState", value: {
        ...this.drugstoreState,
        ...dateByDay,
        label: this.label,
        startAt: dateByDay.zonedStartDate
      }, });
      this.changeTrip("VerificationDataDate");
    },

    async fetchDaysFromDate(date){
      this.handleShowModal(loadingMessage({
        title: "Consultando horario...",
        state: "loading",
      }));
      
      // Check available days
      let day = date.format('YYYY-MM-DDTh:mm:ss');
      await this.fetchAvailablebyMonths({
        url: this.env.VUE_APPOINTMENTS_DEBMEDIA_SERVER,
        headers: {
          'token_appointments': this.env.VUE_APPOINTMENTS_DEBMEDIA_API_KEY,
        },
        scheduleId:this.schedule.id,
        branchId: this.drugstoreState.branch_id,
        startAt: `${day}${this.defaultSheet.timezone.replace(':', '')}`
      });

      this.closeModal();
      
      if(this.availablesDaysMonth && this.availablesDaysMonth.slots){

        let availables = {}
        this.availablesDaysMonth.slots.map(a=>availables[moment(a.zonedStartDate).format('YYYY:MM:DD')] = {
          start: new Date(moment(a.zonedStartDate).format('YYYY'), moment(a.zonedStartDate).format('MM')-1, moment(a.zonedStartDate).format('DD')),
          end: new Date(moment(a.zonedStartDate).format('YYYY'), moment(a.zonedStartDate).format('MM')-1, moment(a.zonedStartDate).format('DD')),
        });
        let availablesArray = []; 
        for (let index in availables) {
          availablesArray.push(availables[index]);
        }
        
        this.availablesDays = availablesArray.map(a=>a);
        this.disableDays = {};
        
      }else{
        this.availablesDays = [];
        this.disableDays = {
          start: null,
          end: new Date(date.format('YYYY'), date.format('MM')-1, 31)
        },
        {
          start: new Date(date.format('YYYY'), date.format('MM')-1, 1),
          end: null
        };
      }


    },

    async getDatesByDay(day) {
      
      this.datesByDay = [];

      if(this.availablesDaysMonth.slots){
        let slots = this.availablesDaysMonth.slots.filter(s=>{
          return moment(s.startDate).format('YYYY-MM-DD') >= moment(day.date).format('YYYY-MM-DD') &&  moment(s.startDate).format('YYYY-MM-DD') <= moment(day.date).format('YYYY-MM-DD')
        } );

        let slotsAvailavility = slots.filter((slot) => slot.availability > 0);

        this.datesByDay = slotsAvailavility.map(a => {
          let hour = a.zonedStartDate.replace('T', ' ').replace(`:00${this.defaultSheet.timezone}`, '');
          return {
            ...a,
            hora: moment(hour).format("h:mm A")
          }
        });
      }
      
      // this.handleShowModal(loadingMessage({
      //   title: "Consultando horario...",
      //   state: "loading",
      // }));
      
      // // Check available days
      // this.datesByDay = [];
      // let dayF = moment(day.date).format("DD");
      // let month = moment(day.date).format("MM");
      // let day_ = moment(day.date).format("YYYY") + '-' + month + '-' + dayF;
      // await this.fetchAvailableDays({
      //   url: this.env.VUE_APPOINTMENTS_DEBMEDIA_SERVER,
      //   headers: {
      //     'token_appointments': this.env.VUE_APPOINTMENTS_DEBMEDIA_API_KEY,
      //   },
      //   scheduleId:this.schedule.id,
      //   branchId: this.drugstoreState.branch_id,
      //   startAt: `${day_}T00:00:00${this.defaultSheet.timezone.replace(':', '')}`
      // });

      // this.closeModal();
      
      // if(this.availables.slots){
      //   console.log(this.availables.slots)
      //   this.datesByDay = this.availables.slots.map(a => {
      //     let hour = a.zonedStartDate.replace('T', ' ').replace(`:00${this.defaultSheet.timezone}`, '');
      //     return {
      //       ...a,
      //       hora: moment(hour).format("HH:mm A")
      //     }
      //   });
      // }     
    },

    sendRange(event){
      this.datesByDay = [];
      this.label='';
      this.fetchDaysFromDate(moment(`${event.year}-${event.month}-01`));
    }
  },
}
</script>

<style scoped>
.specialButton {
  overflow: hidden;
}
.Drugstore {
  margin: 0 1rem;
}
.CalendarContainer {
  max-width: 24rem;
}
.vc-container {
  border: none;
  --tw-shadow: 0px 2px 5px #0000002e;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
</style>